import React, {useEffect, useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Alert } from '@mui/material';
import Container from '@mui/material/Container';
import styled from 'styled-components';
import { SendRegister } from '../../my_methods/session_methods';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MenuItem from '@mui/material/MenuItem';
import { isBefore } from 'date-fns';

import data from '../../provincias.json';



const validationSchema = Yup.object({
  username: Yup.string().required('Campo requerido'),
  name: Yup.string().required('Campo requerido'),
  surname: Yup.string().required('Campo requerido'),
  password: Yup.string().min(8, 'La contraseña debe tener al menos 8 caracteres').required('Campo requerido'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
    .required('Campo requerido'),
  province: Yup.string().required('Campo requerido'),
  city: Yup.string().required('Campo requerido'),
  district: Yup.string().required('Campo requerido'),
  email: Yup.string().email('Dirección de correo electrónico no válida').required('Campo requerido'),
  birthdate: Yup.date()
    .max(new Date(), 'La fecha de nacimiento no puede ser superior a la fecha actual')
    .min(new Date(1920, 0, 1), 'La fecha de nacimiento debe ser posterior a 1920-01-01')
    .required('Campo requerido'),
  phone_number: Yup.string().required('Campo requerido'),
  id_document_type: Yup.string().required('Campo requerido'),
  document: Yup.string().required('Campo requerido'),
});

const initialValues = {
  username: '',
  name: '',
  surname: '',
  password: '',
  repeatPassword: '',
  province: '',
  city: '',
  district: '',
  street: '',
  email: '',
  birthdate: null,
  phone_number: '',
  id_document_type: '',
  document: '',
};

function AdopterRegister() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialog_message , setDialogMessage] = useState('error inesperado');
  const [dialog_state , setDialogState] = useState('error');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = React.useState(false);


  async function SendAdopter(values) {
    var response = await SendRegister(values, 'adopter');
    console.log('should send adopter')
    try {
      if (response.status === 200){
        setIsDialogOpen(true)
        setDialogMessage('usuario creado')
        setDialogState('success')  

        window.location.href="/profile";
      }
      else{
        setIsDialogOpen(true)
        setDialogMessage('Ha ocurrido un error: ' + response['response'])
        setDialogState('error')  
      }

    } catch (error) {
      setIsDialogOpen(true)
      setDialogMessage('Ha ocurrido un error: error interno')
      setDialogState('error')

    }
  }


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      SendAdopter(values)
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const [data, setData] = useState([]);
  // const [selectedProvince, setSelectedProvince] = useState('');

  // useEffect(() => {
  //   fetch("../src/provincias.json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setData(data);
  //     })
  //     .catch((error) => {
  //       console.error("Error al cargar datos de la API:", error);
  //     });
  // }, []);


  const provincias = data.provincias;

  const provinceOptions = data.provincias.map((provincia) => ({
    value: provincia.id,
    label: provincia.nombre,
  }));
  
 

  return (
    <form onSubmit={formik.handleSubmit} >
      <CenteredContainer >
            {isDialogOpen && (
              <Alert severity={dialog_state}>{dialog_message}</Alert>
            )}
      </CenteredContainer>
      <Grid container spacing={2}>
        
        <Grid item xs={12} sm={6}>
          
          <TextField
            fullWidth
            id="username"
            name="username"
            label="Nombre de usuario"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="surname"
            name="surname"
            label="Apellido"
            value={formik.values.surname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.surname && Boolean(formik.errors.surname)}
            helperText={formik.touched.surname && formik.errors.surname}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <TextField
            fullWidth
            id="password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

        </Grid>
        <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="repeatPassword"
          name="repeatPassword"
          type={showRepeatPassword ? 'text' : 'password'}
          label="Repetir Contraseña"
          value={formik.values.repeatPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
          helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle repeat password visibility"
                  onClick={() => setShowRepeatPassword((show) => !show)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="province"
            select
            name="province"
            label="Provincia"
            value={formik.values.province}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.province && Boolean(formik.errors.province)}
            helperText={formik.touched.province && formik.errors.province}
          >
            {provinceOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>



        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="city"
            name="city"
            label="Ciudad"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="district"
            name="district"
            label="Barrio"
            value={formik.values.district}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.district && Boolean(formik.errors.district)}
            helperText={formik.touched.district && formik.errors.district}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="street"
            name="street"
            label="Calle"
            value={formik.values.street}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.street && Boolean(formik.errors.street)} 
            helperText={formik.touched.street && formik.errors.street} 
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Correo electrónico"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="birthdate"
            name="birthdate"
            type="date"
            label="Fecha de nacimiento"
            value={formik.values.birthdate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.birthdate && Boolean(formik.errors.birthdate)}
            helperText={formik.touched.birthdate && formik.errors.birthdate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="phone_number"
            name="phone_number"
            label="Número de teléfono"
            value={formik.values.phone_number}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^[0-9]*$/.test(inputValue)) {
                formik.handleChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
            helperText={formik.touched.phone_number && formik.errors.phone_number}                 
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <p>Tipo de documento de identidad </p>
          <RadioGroup
            row
            aria-label="Tipo de documento de identidad"
            name="id_document_type"
            value={formik.values.id_document_type}
            onChange={formik.handleChange}
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="LIBRETA CÍVICA"
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label="DNI"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="document"
            name="document"
            label="Número de documento de identidad"
            value={formik.values.document}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.document && Boolean(formik.errors.document)}
            helperText={formik.touched.document && formik.errors.document}
          />
        </Grid>
        <Grid item xs={12}>
        <p style={{ marginTop: '10px', textAlign: 'center' }}><a href="/login">¿Ya tienes una cuenta? Inicia sesión.</a></p>
          <Button variant="contained" color="primary" type="submit" style={{width:'100%'}}>
            Registrarse
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default AdopterRegister;

const CenteredContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px !important; 
`;