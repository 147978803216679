import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ShelterContent from './ShelterContent';
import PetContent from './PetContent';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'black',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: 'black',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(255,255,255, 1)',
    },
  }),
);

export default function CustomizedTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', height:'100%'}}>
      <Box sx={{ bgcolor: '#fffff', marginTop: '50px', color:'black', width:'100%', display:'flex', justifyContent:'center' }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab sx={{color:'black', fontSize:'25px', fontWeight:'bold'}} label="MASCOTA" />
          <StyledTab sx={{color:'black', fontSize:'25px', fontWeight:'bold'}} label="REFUGIO" />
        </StyledTabs>
        <Box sx={{ p: 3 }} />
      </Box>
      <Box sx={{ p: 3 }}>
        {value === 0 ? <PetContent /> : <ShelterContent />}
      </Box>
    </Box>
  );
};
